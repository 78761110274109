<template>
  <div>

      <!----- 搜索框 start------->
      <el-form :inline="true" class="search">
          <el-form-item label="状态">
              <el-select v-model="searchData.status" placeholder="搜索状态">
                  <el-option :value="1" label="已发送"></el-option>
                  <el-option :value="2" label="已撤回"></el-option>
              </el-select>
          </el-form-item>
          <el-form-item>
              <el-button @click="getMessages()">查询</el-button>
          </el-form-item>
      </el-form>
      <!----- 搜索框 end------->


      <!----- 表格 start------->
      <el-table :data="tableData" :border="true" empty-text="暂无数据" style="width: 100%">
          <el-table-column prop="id" label="ID"  />
          <el-table-column prop="name" label="任务类型">
              <template #default="scope">
                <span v-text="proxy.type[scope.row.type]"></span>
              </template>
          </el-table-column>
          <el-table-column prop="group_name" label="发送的群"></el-table-column>
          <el-table-column label="消息内容">
              <template #default="scope">
                  <div v-show="scope.row.type === 'MT_SEND_IMGMSG_BY_URL'">
                      <a :href="scope.row.content.url" target="_blank">点击查看图片</a>
                  </div>
                <div v-show="scope.row.type === 'MT_SEND_IMGMSG'">
                  <span v-text="scope.row.content.file"></span>
                </div>
                  <div v-show="['MT_SEND_CHATROOM_AT_V2_MSG', 'MT_SEND_TEXT_V2_MSG'].indexOf(scope.row.type) > -1">
                      内容:<span v-text="scope.row.content.content"></span>
                  </div>
              </template>
          </el-table-column>
          <el-table-column label="状态">
              <template #default="scope">
                  <span v-show="scope.row.status === 1">已发送</span>
                  <span v-show="scope.row.status === 2" style="color:red;">已撤回</span>
              </template>
          </el-table-column>
          <el-table-column prop="created_at" label="发送时间"></el-table-column>
          <el-table-column label="操作">
              <template #default="scope">
                  <el-button
                      v-show="scope.row.status === 1 && scope.row.is_revoke"
                      type="warning"
                      size="small"
                      @click="clickRevoke(scope.row)">
                      撤回
                  </el-button>
                  <span v-show="!(scope.row.status === 1 && scope.row.is_revoke)">
                      不可撤回
                  </span>
              </template>
          </el-table-column>
      </el-table>
      <!----- 表格 end------->

      <!----- 分页 start------->
      <div class="cherry-page" v-show="pageData.total">
          <el-pagination
                  small
                  background
                  layout="prev, pager, next, total"
                  :total="pageData.total"
                  v-model:current-page="searchData.page"
                  @update:current-page="getMessages()"
                  class="mt-4"
          />
      </div>
      <!----- 分页 end------->
  </div>
</template>

<script>
import {apiGetWxMessages, apiRevokeMessage} from '@/api/wechatApi'
export default {
    data () {
        return {
            tableData: [],
            searchData: {
                page: 1,
            },
            proxy: {
              type: {
                'MT_SEND_CHATROOM_AT_V2_MSG': 'at消息',
                'MT_SEND_IMGMSG_BY_URL': '远程图片消息',
                'MT_SEND_TEXT_V2_MSG': '文本消息',
                'MT_SEND_IMGMSG': '本地图片消息',
              }
            },
            pageData: {
                total: 0,
            },
        }
    },
    created() {
        this.getMessages()
    },
    methods: {
        // 获取消息
        getMessages(){
            apiGetWxMessages(this.searchData).then((data) => {
                this.tableData = data.data
                this.pageData = data.meta
            })
        },
        // 撤回消息
        clickRevoke (item) {
            this.$messageBox.confirm('确定撤回吗').then(() => {
                apiRevokeMessage(item.id).then(() => {
                    this.$message.success('撤回成功')

                    setTimeout(() => {
                        this.getMessages()
                    }, 3000)
                })
            })
        }
    }
}
</script>
